import React, { useEffect, useState } from "react";
import { Card, Table, Button, Modal, Form, Input, Select, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { createCarRate, deleteCarRate, getAllCarRate, getAllCarType, updateCarRate } from "./store/action_creator";
const { Option } = Select
const { Search } = Input


const CarRate = () => {
    const allCarRate = useSelector((state) => state?.car?.carsRate)
    const carsType = useSelector((state) => state?.car?.carsType)
    const [types, setcarType] = useState([])
    const [visible, setVisible] = useState(false);
    const [editingcarRate, setEditingCarRate] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        setLoading(true)
        dispatch(getAllCarRate())
          .then(() => dispatch(getAllCarType()))
          .finally(() => setLoading(false));
      }, [dispatch]);

    useEffect(() => {
        if (editingcarRate) {
            form.setFieldsValue(editingcarRate);
        }
    }, [editingcarRate, form]);

    useEffect(() => {
        if (carsType) {
            setcarType(carsType)
        }
    }, [carsType])

    useEffect(() => {
        if(searchText.length>1) {
          const propertiesToSearch = ["carType", "baseFare", "distanceBelow_40","distanceBetween_40_To_50", "distanceAbove_50", "gst"];
          const lowercaseSearch = searchText.toLowerCase();
          const filteredArray = allCarRate.filter(item => {
            for (const prop of propertiesToSearch) {
                const lowercaseValue = item[prop] ? item[prop].toString().toLowerCase() : '';
              if (lowercaseValue.includes(lowercaseSearch)) {
                return true; 
              }
            }
            return false;
          });
          setFilteredData(filteredArray)
        }else {
          setFilteredData(allCarRate)
        }
    }, [searchText, allCarRate]);


    const handleCreateRate = () => {
        setEditingCarRate(null)
        setIsEditMode(false);
        setVisible(true);
    };

    const handleEditCarRate = (record) => {
        setEditingCarRate(record)
        setIsEditMode(true);
        setVisible(true);
    }

    const handleDeleteCarRate = (record) => {
        setLoading(true)
        dispatch(deleteCarRate(record.rateId)).then(res => {
            dispatch(getAllCarRate(res)).then(res => {
            setLoading(false)
          })
        })
      }

    const handleCancel = () => {
        setEditingCarRate(null)
        form.resetFields();
        setVisible(false);
    };

    const handleInputChange = (key, value) => {
        setEditingCarRate((prevValues) => ({
            ...prevValues,
            [key]: value
        }));
    };

    const handleFormSubmit = async (values) => {
        try {
          setLoading(true); 
          setVisible(false);
          form.resetFields(); 
      
          if (isEditMode && editingcarRate) {
            let formValues = JSON.parse(JSON.stringify(editingcarRate))
            let selectedCar = types.find(item => item.carTypeId === formValues.carTypeId)
            formValues['carType'] = selectedCar.type
            await dispatch(updateCarRate(formValues))
          } else {
            let formValues = JSON.parse(JSON.stringify(values))
            let selectedCar = types.find(item => item.carTypeId === formValues.carTypeId)
            formValues['carType'] = selectedCar.type
            await dispatch(createCarRate(formValues));
          }
      
          await dispatch(getAllCarRate())
          setLoading(false);
        } catch (error) {
          message.error(error)
          setLoading(false); 
        }
      };

    const cancel = () => {
        console.log('Deletion canceled.');
    };

    const columns = [
        {
            title: 'Car Type',
            dataIndex: 'carType',
            key: 'carType',
        },
        {
            title: 'Base Fare',
            dataIndex: 'baseFare',
            key: 'baseFare',
        },
        {
            title: 'Per KM Rate in INR',
            colSpan: 3, 
            children: [
                {
                    title: 'Distance < 40Km',
                    dataIndex: 'distanceBelow_40',
                    key: 'distanceBelow_40',
                },
                {
                    title: '40Km < Distance < 50Km',
                    dataIndex: 'distanceBetween_40_To_50',
                    key: 'distanceBetween_40_To_50',
                }, 
                 {
                    title: 'Distance > 50Km',
                    dataIndex: 'distanceAbove_50',
                    key: 'distanceAbove_50',
                }
            ],
        },
        {
            title: 'GST',
            dataIndex: 'gst',
            key: 'gst',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <EditOutlined style={{ fontSize: '16px', color: '#08c' }} onClick={() => handleEditCarRate(record)} />
                    <Popconfirm title="Are you sure to delete this car rates?" onConfirm={() => handleDeleteCarRate(record)} onCancel={cancel} okText="Yes" cancelText="No">
                        <DeleteOutlined style={{ fontSize: '16px', color: '#D00', paddingLeft: '10px' }} />
                    </Popconfirm>
                </span>
            ),
        }
        
    ];


    return (
        <Card
            title="Cars Rate"
            extra={ 
                <span style={{display: 'flex', flexFlow: 'row', marginTop: '10px'}}>
                    <Button type="primary" onClick={() => handleCreateRate()}>Create</Button>
                    <Search placeholder="Search" enterButton onChange={(e) => setSearchText(e.target.value)}/>
                </span>
            }>
            <Table className="gx-table-responsive" columns={columns} dataSource={filteredData} loading={loading} bordered={true} />
          
            <Modal
                title={isEditMode ? "Edit Car Rate" : "Create Car Rate"}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                className="modalStyle"
                >

                <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>

                    <Form.Item
                        label="Base Fare"
                        name="baseFare"
                        rules={[{ required: true, message: 'Please enter the base fare' }]}>
                        <Input prefix="₹" type="number" placeholder="Enter the base fare" onChange={(e) => handleInputChange("baseFare", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="Distance < 40"
                        name="distanceBelow_40"
                        rules={[{ required: true, message: 'Please Enter the per distance below 40' }]}>
                        <Input prefix="₹" type="number" placeholder="Enter the per distance below 40" onChange={(e) => handleInputChange("distanceBelow_40", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="40 < Dist < 50"
                        name="distanceBetween_40_To_50"
                        rules={[{ required: true, message: 'Please enter the distance btw 40 - 50' }]}>
                        <Input prefix="₹" type="number" placeholder="Please enter the distance btw 40 - 50" onChange={(e) => handleInputChange("distanceBetween_40_To_50", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="Distance > 50"
                        name="distanceAbove_50"
                        rules={[{ required: true, message: 'Please Enter the per distance greater 50' }]}>
                        <Input prefix="₹" type="number" placeholder="Enter the per distance greater 50" onChange={(e) => handleInputChange("distanceAbove_50", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="GST"
                        name="gst"
                        rules={[{ required: true, message: 'Please enter the Gst rate' }]}>
                        <Input prefix="₹" type="number" placeholder="Enter the Gst rate" onChange={(e) => handleInputChange("gst", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        name="carTypeId"
                        label="Type"
                        rules={[{ required: true, message: 'Please select Type!' }]}>
                        <Select placeholder="select your type" onChange={(value) =>  handleInputChange("carTypeId", value)}>
                            {types.map((type, index) => (
                                <Option key={index} value={type.carTypeId}>
                                    {type.type}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
                        <Button type="danger" onClick={handleCancel}>
                            cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {isEditMode ? "Update" : "Create"}
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>
        </Card>
    )
}

export default CarRate;