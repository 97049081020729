import React, { useEffect, useState } from "react";
import { Card, Table, Button, Modal, Form, Input, Select, message } from "antd";
import { CommonService } from "../../../_services/CommonService";
import moment from "moment";
const { Option } = Select;
const { Search } = Input;

const Payments = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    getAllPayments()
  }, [])

  useEffect(() => {
    if(searchText.length>2) {
      const propertiesToSearch = ["bookingRef", "paymentId", "upiId","driverName", "distance", "fare",
       "basic", "tax", "paymentMode", "paymentStatus", "carNo", "carType"];
      const lowercaseSearch = searchText.toLowerCase();
      const filteredArray = data.filter(item => {
        for (const prop of propertiesToSearch) {
            const lowercaseValue = item[prop] ? item[prop].toString().toLowerCase() : '';
            if (lowercaseValue.includes(lowercaseSearch)) {
                return true; 
            }
        }
        return false;
      });
      setFilteredData(filteredArray)
    }else {
      setFilteredData(data)
    }
}, [searchText, data]);



  const handleCancel = () => {
    setVisible(false);
  };

  const getAllPayments = () => {
    setLoading(true)
    CommonService.httpGetService(`/cars/payment`).then((res)=> {
      setLoading(false)
      if(res && res['data']) {
        setData(res['data'])
      }
  }).catch(err => {
      setLoading(false)
      message.error(err)
  })
  }

  const handleFormSubmit = (values) => {
    console.log(values);
    setVisible(false);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const columns = [
    {
      title: "Booking Ref",
      dataIndex: "bookingRef",
      key: "bookingRef",
      fixed: 'left'
    },
    {
      title: "Payment Id",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "UPI ID",
      dataIndex: "upiId",
      key: "upiId",
    },
    {
      title: "Driver",
      dataIndex: "driverName",
      key: "driverName",
    },
    {
      title: "Distance",
      render: (_, record) => {
        return <span>{record.distance && record.distance.toFixed(2)}</span>
    }
    },

    {
      title: "Fare",
      dataIndex: "fare",
      key: "fare",
    },
    {
      title: "Base",
      dataIndex: "basic",
      key: "basic",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
    {
      title: 'Payment Time',
      render: (_, record) => {
          const formattedDate = moment(record.paymentTimestamp).format("DD-MM-YYYY HH:mm:ss");
          return <span>{formattedDate}</span>
      }
    },
    {
      title: "Car Type",
      dataIndex: "carType",
      key: "carType",
    },
    {
      title: "Car No",
      dataIndex: "carNo",
      key: "carNo",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
  ];


  return (
    <Card title="Payments"            
      extra={<Search placeholder="Search" enterButton onChange={(e) => setSearchText(e.target.value)}/>}

    >
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={filteredData}
        bordered={true}
        loading={loading}
      />
      <Modal
        title="Create User"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        // style={modalStyle}
      >
        <Form
          onFinish={handleFormSubmit}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter the name" }]}
          >
            <Input placeholder="name" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter the email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="username"
            label="Username"
            tooltip="What do you want others to call you?"
            rules={[
              {
                required: true,
                message: "Please input your nickname!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please input your password!" }]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: "Please select gender!" }]}
          >
            <Select placeholder="select your gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="User Type"
            name="user_type"
            rules={[{ required: true, message: "Please enter the type" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="User status"
            name="user_status"
            rules={[{ required: true, message: "Please enter the status" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item style={{ marginTop: "40px", marginLeft: "150px" }}>
            <Button type="danger" onClick={handleCancel}>
              cancel
            </Button>
            <Button type="primary" htmlType="submit">
              save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default Payments;
