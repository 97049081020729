import React, { useState } from "react";
import { DeleteOutlined } from '@ant-design/icons';
import { Card, Table, Button, Modal, Form, Input, Select } from "antd";
const { Option } = Select

const Reviews = () => {
  const [visible, setVisible] = useState(false);

  // const handleCreateUser = () => {
  //   setVisible(true);
  // };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
    setVisible(false);
  };

  // const modalStyle = {
  //   top: 170,
  //   height: "60vh",
  //   overflowY: "auto",
  // };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );


  const columns = [
    {
        title: 'Id',
        dataIndex: 'key',
        key: 'key',
        render: text => <span className="gx-link">{text}</span>,
      },
    {
      title: 'Ride Id',
      dataIndex: 'ride_id',
      key: 'ride_id',
    },
    {
      title: 'User Id',
      dataIndex: 'user_id',
      key: 'user_id',
    },
    {
      title: 'Driver Id',
      dataIndex: 'driver_id',
      key: 'driver_id',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
    },
  
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <DeleteOutlined style={{ fontSize: '16px', color: '#D00' }} />
        </span>
      ),
    }
  ];

  const data = [
    {
      key: '1',
      ride_id: '12121212',
      user_id: '5454545',
      driver_id: '47445454',
      comment: '9873652457',
      rating: 32,
     
    },
    {
      key: '2',
      ride_id: '12121212',
      user_id: '5454545',
      driver_id: '47445454',
      comment: '9873652457',
      rating: 32,
    },
    {
      key: '3',
      ride_id: '12121212',
      user_id: '5454545',
      driver_id: '47445454',
      comment: '9873652457',
      rating: 32,
    }
  ];

  return (
    <Card
      title="Reviews"
      >
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={data}
        bordered={true} />
      <Modal
        title="Create User"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        // style={modalStyle}
        >

        <Form onFinish={handleFormSubmit} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>

          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter the name' }]}>
            <Input placeholder="name" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter the email' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="username"
            label="Username"
            tooltip="What do you want others to call you?"
            rules={[{ required: true, message: 'Please input your nickname!', whitespace: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            hasFeedback>
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[{ required: true, message: 'Please input your phone number!' }]}>
            <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: 'Please select gender!' }]}>
            <Select placeholder="select your gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="User Type"
            name="user_type"
            rules={[{ required: true, message: 'Please enter the type' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="User status"
            name="user_status"
            rules={[{ required: true, message: 'Please enter the status' }]}>
            <Input />
          </Form.Item>

          <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
            <Button type="danger" onClick={handleCancel}>
              cancel
            </Button>
            <Button type="primary" htmlType="submit">
              save
            </Button>
          </Form.Item>

        </Form>

      </Modal>
    </Card>
  );
}

export default Reviews;
