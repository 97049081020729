import * as actionTypes from './action-type'
import { CommonService } from '../../../../../src/_services/CommonService'
import {message} from "antd";


export const setAllUsers = (data) => {
    return {
        type: actionTypes.SET_USERS,
        data
    }
}

export const getAllUsers = () => {
    return (dispatch) => {
        return CommonService.httpGetService(`/cars/user?user=customer`).then((res) => {
            if (res && res['data']) {
                dispatch(setAllUsers(res['data']))
            }else{
                dispatch(setAllUsers(null))
            }
        }).catch(err => {
            message.error(err);
        })
    }
}

export const createUser = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/cars/user', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const editUser = (data) => {
    return (dispatch) => {
        return CommonService.httpPutService('/cars/user', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}


export const updateUserPassword = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/password/change', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}


export const deleteUser = (id) => {
    return (dispatch) => {
        return CommonService.httpDeleteService(`/cars/user/${id}`).then((res)=> {
           message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}
