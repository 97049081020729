import { CommonService } from '../../../../_services/CommonService'
import * as actionTypes from './action-type'
import { message } from "antd";


export const setAllDriver = (data) => {
    return {
        type: actionTypes.SET_DRIVER,
        data
    }
}

export const setAllDriverLocation = (data) => {
    return {
        type: actionTypes.SET_DRIVER_LOCATION,
        data
    }
}


export const getAllDriver = () => {
    return (dispatch) => {
        return CommonService.httpGetService('/cars/user?user=driver').then((res) => {
            if (res && res['data']) {
                dispatch(setAllDriver(res['data']))
            } else {
                dispatch(setAllDriver(null))
            }
        }).catch(err => {
            message.error(err);
        })
    }
}

export const createDriver = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/cars/user',data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const editDriver = (data) => {
    return (dispatch) => {
        return CommonService.httpPutService('/cars/user', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const deleteDriver = (id) => {
    return (dispatch) => {
        return CommonService.httpDeleteService(`/cars/user/${id}`).then((res)=> {
           message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}


export const getAllDriverLocation = () => {
    return (dispatch) => {
        return CommonService.httpGetService('/driver/locations/all').then((res) => {
            if (res && res['driverLocations']) {
                dispatch(setAllDriverLocation(res['driverLocations']))
            } else {
                dispatch(setAllDriverLocation(null))
            }
        }).catch(err => {
            message.error(err);
        })
    }
}


export const createDriverLocation = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/driver/locations',data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}


export const editDriverLocation = (data) => {
    return (dispatch) => {
        return CommonService.httpPutService('/driver/locations', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}


export const deleteDriverLocation = (id) => {
    return (dispatch) => {
        return CommonService.httpDeleteService(`/driver/locations/${id}`).then((res)=> {
           message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

