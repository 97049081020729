import React from "react";
import { Route, Switch  } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import user from "../views/components/User/User";
import Car from "../views/components/Car/Car";
import Ride from "../views/components/Ride/Ride";
import Driver from "../views/components/Driver/Driver";
// import DriverLocations from "../views/components/Driver/DriverLocations";
import RideHistory from "../views/components/Ride/RideHistory";
import Reviews from "../views/components/Reviews/Reviews";
import Payments from "../views/components/Payments/Payments";
import CarType from "../views/components/Car/CarType";
import CarRate from "../views/components/Car/CarRate";
import DriverLiveTracking from "../views/components/Driver/DriverLiveTracking";
import Airport from "../views/components/Airport/Airport";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))} />
        <Route component={user} path='/user' />
        <Route component={Car} path='/cars' />
        <Route component={CarType} path='/cars_type' />
        <Route component={CarRate} path='/city_rate' />
        <Route component={Airport} path='/airport_rate' />
        <Route component={Ride} path='/ride' />
        <Route component={Driver} path='/driver' />
        <Route component={DriverLiveTracking} path='/driverLocation' />
        <Route component={RideHistory} path='/rideHistory' />
        <Route component={Reviews} path='/review' />
        <Route component={Payments} path='/payment' />
      </Switch>
  </div>
);

export default App;
