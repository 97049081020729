import React from "react";
import {Avatar, Popover} from "antd";
import {useAuth} from "../../authentication";
import {useHistory} from "react-router-dom";
import { Popconfirm } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getAllUsers } from "../../views/components/User/store/action-creator";
import { useEffect } from "react";
import { useState } from "react";


const UserProfile = () => {
  const allUsers = useSelector((state) => state?.user?.users)
  const [name, setName] = useState([])
  const {userSignOut} = useAuth();
  const history = useHistory();
  const {authUser} = useAuth();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch])

  useEffect(() => {
    if (authUser && allUsers && allUsers.length > 0) {
      const loggedInUser = allUsers.find((user) => user.userId === authUser.userId);
      if (loggedInUser) {
        setName(loggedInUser.name);
      }
    }
  }, [authUser, allUsers]);

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/signin');
    });
  }

  const cancel = () => {
    console.log('Deletion canceled.');
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      {/* <li>Connections</li> */}
      <li>
        <Popconfirm title="Are you sure want to Logout?" onConfirm={() => onLogoutClick()} onCancel={cancel} okText="Yes" cancelText="No">Logout</Popconfirm>
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={"https://via.placeholder.com/150"} className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{name}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>
  )
};

export default UserProfile;
