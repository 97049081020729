import React, { useEffect, useState } from "react";
import moment from 'moment';
import { EditOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons';
import { Card, Table, Button, Modal, Form, Input, Select, Popconfirm, message } from "antd";
import { useDispatch, useSelector } from "react-redux"
import { createUser, deleteUser, editUser, getAllUsers, updateUserPassword } from "./store/action-creator";
const { Option } = Select
const { Search } = Input

const User = () => {
  const allUsers = useSelector((state) => state?.user?.users)
  const [filteredData, setFilteredData] = useState([])
  const [editingUser, setEditingUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading,setLoading] = useState(false)
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    setLoading(true)
    dispatch(getAllUsers()).then(res=> {
      setLoading(false)
    })
  }, [dispatch])

  useEffect(() => {
    if (editingUser) {
      form.setFieldsValue(editingUser);
    }
  }, [editingUser, form]);

  useEffect(() => {
    if(openPasswordModal) {
      setEditingUser((prevValues) => ({
        ...prevValues,
        password : ''
      }));
    }
  }, [openPasswordModal])

  useEffect(() => {
    if(searchText.length>2) {
      const propertiesToSearch = ["name", "email", "gender", "phoneNumber", "userType", "userStatus"];
      const lowercaseSearch = searchText.toLowerCase();
      
      const filteredArray = allUsers.filter(item => {
        for (const prop of propertiesToSearch) {
          const lowercaseValue = item[prop] ? item[prop].toString().toLowerCase() : '';
          if (lowercaseValue.includes(lowercaseSearch)) {
            return true; 
          }
        }
        return false;
      });
      setFilteredData(filteredArray)
    }else {
      setFilteredData(allUsers)
    }
  }, [searchText, allUsers]);
  
  const handleCreateUser = () => {
    setEditingUser(null);
    setIsEditMode(false);
    setVisible(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setIsEditMode(true)
    setVisible(true);
  };

  const handleDelete = (record) => {
    setLoading(true)
    dispatch(deleteUser(record.userId)).then(res => {
      dispatch(getAllUsers(res)).then(res => {
        setLoading(false)
      })
    })
  }

  const handleCancel = () => {  
    setEditingUser(null);
    form.resetFields();
    setVisible(false);
  };

  const handleInputChange = (key, value) => {
    setEditingUser((prevValues) => ({
      ...prevValues,
      [key]: value
    }));
  };

const handleFormSubmit = async (values) => {
  try {
    setLoading(true); 
    setVisible(false);
    form.resetFields(); 

    if (isEditMode && editingUser) {
      await dispatch(editUser(editingUser));
    } else {
      const capitalizedValues = { ...values, name: values.name.toUpperCase() };
      console.log(capitalizedValues,'capitalizedValues');
      await dispatch(createUser(capitalizedValues));
    }

    await dispatch(getAllUsers());
    setLoading(false);
  } catch (error) {
    message.error(error)
    setLoading(false); 
  }
};
  
  const cancel = () => {
    console.log('Deletion canceled.');
  };

  const validatePhoneNumber = (_, value) => {
    const phoneNumberRegex = /^\d{10}$/;
    if (!value || !value.match(phoneNumberRegex)) {
      return Promise.reject('Phone number must be a 10-digit number');
    }
    return Promise.resolve();
  };

  const columns = [
    {
      title: 'Index',
      key: 'index',
      render: (text, record, index) => (
        <span className="gx-link">{index + 1}</span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Mobile',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Type',
      dataIndex: 'userType',
      key: 'userType',
    },
    {
      title: 'Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
      render: text => (
        <span style={{ color: text === 'active' ? 'green' : text === 'inactive' ? 'red' : text === 'pending' ? '#d6d600' : ''}}>
          {text === 'active' ? 'Active' : text === 'inactive' ? 'In-Active' : text === 'pending' ? 'Pending' : text}
        </span>
      )
    }, 
    {
      title: 'Created At',
      render: (_, record) => {
        const formattedDate = moment(record.registrationTimestamp).format("DD-MM-YYYY HH:mm:ss");
        return <span>{formattedDate}</span>
      }

    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <EditOutlined style={{ fontSize: '16px', color: '#08c' }} onClick={() => handleEditUser(record)} />
          <Popconfirm title="Are you sure to delete this user?" onConfirm={() => handleDelete(record)} onCancel={cancel} okText="Yes" cancelText="No">
            <DeleteOutlined style={{ fontSize: '16px', color: '#D00', paddingLeft: '10px' }} />
          </Popconfirm>
          <Popconfirm title="Are you sure to reset this user password?" onConfirm={() => {
                setEditingUser(record);
                setIsEditMode(true)
                setOpenPasswordModal(true);
                setVisible(false);
              }} onCancel={cancel} okText="Yes" cancelText="No">
            <LockOutlined style={{ fontSize: '16px', color: '#eb2f96', paddingLeft: '10px' }} />
          </Popconfirm>
        </span>
      ),
    }
  ];


  const updatePassword = async() => {
    try {
      setLoading(true); 
      setOpenPasswordModal(false);
      form.resetFields(); 
      let reqData = {
        newPassword: editingUser.password,
        userId: editingUser.userId
      }
      dispatch(updateUserPassword(reqData)).then(res => {
        setIsEditMode(false)
        setEditingUser(null)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    } catch (error) {
      message.error(error)
      setLoading(false); 
    }
  }

  return (
    <Card
      title="USERS"
      extra={
        <span style={{display: 'flex', flexFlow: 'row', marginTop: '10px'}}>
            <Button type="primary" onClick={handleCreateUser}>Create User</Button>
            <Search placeholder="Search" enterButton onChange={(e) => setSearchText(e.target.value)}/>
        </span>


      }>
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={filteredData}
        bordered={true} 
        loading={loading}/>
      <Modal
        title={isEditMode ? "Edit User" : "Create User"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        className="modalStyle"
        >

        <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>

          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter the name' }]}>
            <Input placeholder="Enter your name" onChange={(e) => handleInputChange("name", e.target.value)} />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            >
            <Input placeholder="Enter your email" onChange={(e) => handleInputChange("email", e.target.value)} />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true },
              { validator: validatePhoneNumber },
            ]}>
            <Input type="number" placeholder="Enter your phoneNumber" style={{ width: '100%' }} onChange={(e) => handleInputChange("phoneNumber", e.target.value)} />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: 'Please select gender!' }]}>
            <Select placeholder="Select your gender"  onChange={(value) => handleInputChange("gender", value)}>
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>


          <Form.Item
            name="userType"
            label="UserType"
            rules={[{ required: true, message: 'Please select user type!' }]}>
            <Select placeholder="Select your user type"  onChange={(value) => handleInputChange("userType", value)}>
              <Option value="Customer">Customer</Option>
              <Option value="Staff">Staff</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Status"
            name="userStatus"
            rules={[{ required: true, message: 'Please enter the status' }]}>
            <Select placeholder="Select your user status" onChange={(value) => handleInputChange("userStatus", value)}>
              <Option value="active">Active</Option>
              <Option value="inactive">In-Active</Option>
            </Select>
          </Form.Item>

          <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
            <Button type="danger" onClick={handleCancel}>
              cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Create"}
            </Button>
            
          </Form.Item>

        </Form>

      </Modal>

      <Modal
        title={`Change ${editingUser && editingUser.name ? `${editingUser.name}'s` : '' } password?`}
        visible={openPasswordModal}
        onCancel={() => setOpenPasswordModal(false)}
        footer={null}
        className="modalStyle"
        >

        <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter the password' }]}>
            <Input placeholder="Enter your password" onChange={(e) => handleInputChange("password", e.target.value)} />
          </Form.Item>

          <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
            <Button type="primary" onClick={updatePassword}>
              Change
            </Button>
            
          </Form.Item>

        </Form>

      </Modal>


    </Card>
  );
}

export default User;
