import * as actionTypes from './action_type'

const intialState = {
    cars: [],
    carsType: [],
    carsRate: [],
    unAllocatedDrivers : []
}

export const carReducer= (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CARS:
            return {
                ...state,
                cars: action.data
            };
        case actionTypes.SET_CARS_TYPE:
            return {
                ...state,
                carsType: action.data
            };   
        case actionTypes.SET_CARS_RATE:
            return {
                ...state,
                carsRate: action.data 
            }
        case actionTypes.SET_UAC_DRIVERS:
            return {
                ...state,
                unAllocatedDrivers: action.data 
            }    
        default:
            return state;
    }
}