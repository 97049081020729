import { message } from 'antd'
import { CommonService } from '../../../../_services/CommonService'
import * as actionTypes from './action_type'


export const setAllAirportCars = (data) => {
    return {
        type: actionTypes.SET_AIRPORT_CARS,
        data
    }
}


export const getAllAirportCars = () => {
    return (dispatch) => {
        return CommonService.httpGetService('/cars/rate/airport').then((res) => {
            if (res && res['data']) {
                dispatch(setAllAirportCars(res['data']))
            } else {
                dispatch(setAllAirportCars(null))
            }
        }).catch(err => {
            message.error(err);
        })
    }
}

export const updateAirportCar = (data) => {
    return (dispatch) => {
        return CommonService.httpPutService('/cars/rate/airport', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}

export const createAirportCar = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/cars/rate/airport', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err);
        })
    }
}


export const deleteAirportCar = (id) => {
    return (dispatch) => {
        return CommonService.httpDeleteService(`/cars/rate/airport/${id}`).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err)
        })
    }
}