import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  MarkerClusterer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import CarIcon from '../../../assets/vendors/icons/aadesh_cabs.png'
import { Centrifuge } from 'centrifuge';

const DriverLiveTracking = () => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [liveTracking, setLiveTracking] = useState([]);

  const [centerPosition, setCenterPosition] = useState({
    lat: 22.28975905987323,
    lng: 70.7831458957536,
  });

  useEffect(() => {
    subscribeCentrifuge('alc')
  }, [])

  const subscribeCentrifuge = (channelName) => {
    try {
    
      const centrifuge = new Centrifuge('wss://cabs.stage.opskube.com/connection/websocket?cf_protocol_version=v2');
      centrifuge.setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJzeXN0YWNjIiwiZXhwIjoxOTg5NDE0MTc5fQ.y1oyn1zqseHQKEMWSiq-NcIUU0VoXnaqTKkHTPR0tQs');
      centrifuge.connect();

      centrifuge.on('connecting', function(ctx) {

      });

      centrifuge.on('connected', function (ctx) {
        console.log("connected", ctx);

        const subs = centrifuge.newSubscription(channelName);

        subs.on('publication', function (ctx) {
          if(ctx.data) {
            setLiveTracking(ctx.data)
          }
          console.log('ctx.data', ctx.data);
        });

        subs.subscribe();
      });

      centrifuge.on('disconnected', function (ctx) {
        console.log("disconnected", ctx);
      });

      centrifuge.on('error', function (err) {
        console.error("Centrifuge Error:", err);
      });

    } catch(error) {
      console.log('error', error);

    }
  }

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  };

  console.log(selectedDriver,'lll');

  return (
    <LoadScript googleMapsApiKey="AIzaSyCaMj8Gm5QB6aXTLeC4NJzItjIU4kmvXUc">
      <GoogleMap
        id="marker-example"
        mapContainerClassName={"map-container"}
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={centerPosition}
      >
        <MarkerClusterer options={options}>
          {(clusterer) =>
            liveTracking && liveTracking.length>0  && liveTracking.map((driver, index) => (
              <Marker
                key={index}
                title={driver.driverName}
                position={{
                  lat: Number(driver.locationLatitude),
                  lng: Number(driver.locationLongitude),
                }}
                icon={{
                    url: CarIcon,
                    scaledSize: new window.google.maps.Size(100,100),
                }}
                clickable={true}
                onClick={(e) => {
                  setShowInfo(true);
                  setSelectedDriver(driver);
                }}
              />
            ))
          }
          
        </MarkerClusterer>
        {showInfo && selectedDriver && (
          <InfoWindow
            onCloseClick={() => {
              setShowInfo(false);
              setSelectedDriver(null);
              setCenterPosition({
                lat: selectedDriver.locationLatitude,
                lng: selectedDriver.locationLongitude,
              });
            }}
            position={{
              lat: Number(selectedDriver.locationLatitude),
              lng: Number(selectedDriver.locationLongitude),
            }}
          >
            <div style={{ padding: 12 }}>
              <div
                style={{
                  fontSize: 16,
                  color: "#08233B", // Corrected fontColor to color
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <strong>
                  {selectedDriver.driverName} 
                    {/* <a href={`tel:9454337811`}>9454337811</a>] */}
                  </strong>
                </div>
                <div>{selectedDriver.carType}</div>
                <div>{selectedDriver.driverMobileNumber || 'N/A'}</div>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default DriverLiveTracking;