import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Common from "./Common";
import { userReducer } from '../../views/components/User/store/action-reducer';
import { carReducer } from '../../views/components/Car/store/action_reducer';
import { rideReducer } from '../../views/components/Ride/srore/action-reducer';
import { driverReducer } from '../../views/components/Driver/store/action-reducer';
import { AirportcarReducer } from '../../views/components/Airport/store/action_reducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  common: Common,
  user : userReducer,
  car : carReducer,
  ride: rideReducer,
  driver: driverReducer,
  airport: AirportcarReducer

});

export default createRootReducer;
