import * as actionTypes from './action-type'

const intialState = {
    drivers: [],
    driverlocation: []
}

export const driverReducer= (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DRIVER:
            return {
                ...state,
                drivers: action.data
            };
        case actionTypes.SET_DRIVER_LOCATION:
            return {
                ...state,
                driverlocation: action.data
            }    
        default:
            return state;
    }
}