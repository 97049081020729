import React, { useState } from "react";
import { Card, Table, Button, Modal, Form, Input } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const RideHistory = () => {

    const [visible, setVisible] = useState(false);

    const handleCreateRide = () => {
        setVisible(true);
    }

    const handleCancel = () => {
        setVisible(false);
    };

    const handleFormSubmit = (values) => {
        setVisible(false);
    };

    // const modalStyle = {
    //     top: 170,
    //     height: "60vh",
    //     overflowY: "auto",
    //   };

    const columns = [
        {
            title: 'Passenger Name',
            dataIndex: 'passenger_name',
            key: 'passenger_name',
            render: text => <span className="gx-link">{text}</span>,
        },
        {
            title: 'Driver Name',
            dataIndex: 'driver_name',
            key: 'driver_name',
        },
        {
            title: 'Pickup Location',
            dataIndex: 'pickup_location_address',
            key: 'pickup_location_address',
        },
        {
            title: 'Pickup Latitude',
            dataIndex: 'pickup_location_latitude',
            key: 'pickup_location_latitude',
        },
        {
            title: 'Pickup Logintude',
            dataIndex: 'pickup_location_longitude',
            key: 'pickup_location_longitude',
        },
        {
            title: 'DropOff Location',
            dataIndex: 'dropoff_location_address',
            key: 'dropoff_location_address',
        },
        {
            title: 'DropOff Latitude',
            dataIndex: 'dropoff_location_latitude',
            key: 'dropoff_location_latitude',
        },
        {
            title: 'DropOff Logintude',
            dataIndex: 'dropoff_location_longitude',
            key: 'dropoff_location_longitudee',
        },
        {
            title: 'Ride Status',
            dataIndex: 'ride_status',
            key: 'ride_status',
        },
        {
            title: 'Payment Mode',
            dataIndex: 'payment_mode',
            key: 'payment_mode',
        },
        {
            title: 'Fare',
            dataIndex: 'fare',
            key: 'fare',
        },
        {
            title: 'Tax',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: 'Ride Start Time',
            dataIndex: 'ride_start_timestamp',
            key: 'ride_start_timestamp',
        },
        {
            title: 'Ride End Time',
            dataIndex: 'ride_end_timestamp',
            key: 'ride_end_timestamp',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <EditOutlined style={{ fontSize: '16px', color: '#08c' }} />
                    <DeleteOutlined style={{ fontSize: '16px', color: '#D00' }} />
                </span>
            ),
        }
    ];


    const data = [
        {
            key: "1",
            passenger_name: "John Doe",
            driver_name: "Jane Smith",
            pickup_location_address: "123 Main Street",
            pickup_location_latitude: "37.1234",
            pickup_location_longitude: "-122.5678",
            dropoff_location_address: "456 Elm Street",
            dropoff_location_latitude: "37.5678",
            dropoff_location_longitude: "-122.1234",
            ride_status: "Completed",
            payment_mode: "Credit Card",
            fare: "₹20",
            tax: "₹2",
            ride_start_timestamp: "2023-07-15 10:00 AM",
            ride_end_timestamp: "2023-07-15 10:30 AM",
        },
        {
            key: "2",
            passenger_name: "Alice Johnson",
            driver_name: "Bob Williams",
            pickup_location_address: "789 Oak Avenue",
            pickup_location_latitude: "37.8765",
            pickup_location_longitude: "-122.4321",
            dropoff_location_address: "321 Pine Street",
            dropoff_location_latitude: "37.5432",
            dropoff_location_longitude: "-122.8765",
            ride_status: "Cancelled",
            payment_mode: "Cash",
            fare: "₹15",
            tax: "₹1.5",
            ride_start_timestamp: "2023-07-15 09:30 AM",
            ride_end_timestamp: "2023-07-15 09:45 AM",
        },
        {
            key: "3",
            passenger_name: "Emily Davis",
            driver_name: "Michael Brown",
            pickup_location_address: "567 Maple Lane",
            pickup_location_latitude: "37.3456",
            pickup_location_longitude: "-122.7654",
            dropoff_location_address: "890 Cedar Road",
            dropoff_location_latitude: "37.6543",
            dropoff_location_longitude: "-122.2345",
            ride_status: "Completed",
            payment_mode: "Credit Card",
            fare: "₹25",
            tax: "₹2.5",
            ride_start_timestamp: "2023-07-15 11:15 AM",
            ride_end_timestamp: "2023-07-15 11:45 AM",
        },
        {
            key: "4",
            passenger_name: "David Wilson",
            driver_name: "Olivia Johnson",
            pickup_location_address: "432 Elm Street",
            pickup_location_latitude: "37.9876",
            pickup_location_longitude: "-122.8765",
            dropoff_location_address: "654 Oak Avenue",
            dropoff_location_latitude: "37.2345",
            dropoff_location_longitude: "-122.3456",
            ride_status: "Completed",
            payment_mode: "Credit Card",
            fare: "₹18",
            tax: "₹1.8",
            ride_start_timestamp: "2023-07-15 02:00 PM",
            ride_end_timestamp: "2023-07-15 02:30 PM",
        },
        {
            key: "5",
            passenger_name: "Sophia Thomas",
            driver_name: "William Davis",
            pickup_location_address: "987 Pine Street",
            pickup_location_latitude: "37.8765",
            pickup_location_longitude: "-122.3456",
            dropoff_location_address: "321 Cedar Road",
            dropoff_location_latitude: "37.4321",
            dropoff_location_longitude: "-122.9876",
            ride_status: "Completed",
            payment_mode: "Credit Card",
            fare: "₹22",
            tax: "₹2.2",
            ride_start_timestamp: "2023-07-15 03:30 PM",
            ride_end_timestamp: "2023-07-15 04:00 PM",
        },
    ];

    return (
        <Card
            title="Ride History"
            extra={<Button style={{ marginTop: '10px' }} type="primary" onClick={handleCreateRide}>Add Ride History</Button>}>
            <Table className="gx-table-responsive" columns={columns} dataSource={data} />
            <Modal
                title="Create Car"
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                className="modalStyle"
                // style={modalStyle}
                >

                <Form onFinish={handleFormSubmit} labelCol={{ span: 8 }} wrapperCol={{ span: 20 }}>

                    <Form.Item
                        label="Passenger Name"
                        name="passenger_name"
                        rules={[{ required: true, message: "Please enter passenger name" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Driver Name"
                        name="driver_name"
                        rules={[{ required: true, message: "Please enter driver name" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Pickup Location"
                        name="pickup_location_address"
                        rules={[{ required: true, message: "Please enter pickup location" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Pickup Latitude"
                        name="pickup_location_latitude"
                        rules={[{ required: true, message: "Please enter pickup latitude" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Pickup Longitude"
                        name="pickup_location_longitude"
                        rules={[{ required: true, message: "Please enter pickup longitude" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Dropoff Location"
                        name="dropoff_location_address"
                        rules={[{ required: true, message: "Please enter dropoff location" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Dropoff Latitude"
                        name="dropoff_location_latitude"
                        rules={[{ required: true, message: "Please enter dropoff latitude" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Dropoff Longitude"
                        name="dropoff_location_longitude"
                        rules={[{ required: true, message: "Please enter dropoff longitude" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Ride Status"
                        name="ride_status"
                        rules={[{ required: true, message: "Please enter ride status" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Payment Mode"
                        name="payment_mode"
                        rules={[{ required: true, message: "Please enter payment mode" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Fare"
                        name="fare"
                        rules={[{ required: true, message: "Please enter fare" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Tax"
                        name="tax"
                        rules={[{ required: true, message: "Please enter tax" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Ride Start Time"
                        name="ride_start_timestamp"
                        rules={[{ required: true, message: "Please enter ride start time" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Ride End Time"
                        name="ride_end_timestamp"
                        rules={[{ required: true, message: "Please enter ride end time" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
                        <Button type="danger" onClick={handleCancel}>
                            cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            save
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>
        </Card>
    )
}

export default RideHistory;