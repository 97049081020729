import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {navStyle, themeType} = useSelector(({settings}) => settings);
  const pathname = useSelector(({common}) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const menuItems = [
    { key: "user", icon: "icon icon-user", label: "sidebar.user" },
    { key: "driver", icon: "icon icon-driver", label: "sidebar.driver" },
    { key: "cars",  icon: "icon icon-car"  , label: "sidebar.cars" },
    { key: "cars_type", icon: "icon icon-car-type", label: "Car Type" },
    { key: "city_rate", icon: "icon icon-car-rate", label: "City Rate" },
    { key: "airport_rate", icon: "icon icon-flight-rate", label: "Airport Rate" },
    { key: "ride", icon: "icon icon-ride", label: "sidebar.ride" },
    { key: "payment", icon: "icon icon-payment", label: "sidebar.payment" },
    // { key: "review", icon: "icon icon-review", label: "sidebar.review" },
    // { key: "rideHistory", icon: "icon icon-ride-history", label: "sidebar.rideHistory" },
    { key: "driverLocation", icon: "icon icon-driver-location", label: "sidebar.driverLocation" },

  ];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {menuItems.map((item) => (
              <Menu.Item key={item.key}>
                <Link to={`/${item.key}`}>
                  <i className={item.icon} />
                  <span>
                    <IntlMessages id={item.label} />
                  </span>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

