import * as actionTypes from './action-type'

const intialState = {
    users: [],
    selectedUser : null
}

export const userReducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USERS:
            return {
                ...state,
                users: action.data
            };
        case actionTypes.SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.data
            };
        default:
            return state;
    }
}