import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons';
import { Card, Table, Button, Modal, Form, Input, Select, Popconfirm, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createDriver, deleteDriver, editDriver, getAllDriver } from "./store/action-creator";
import { updateUserPassword } from "../User/store/action-creator";
const { Option } = Select
const { Search } = Input


const Driver = () => {
  const alldrivers = useSelector((state) => state?.driver?.drivers)
  const [editingdriver, setEditinrDriver] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading,setLoading] = useState(false)
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])


  useEffect(() => {
    setLoading(true)
    dispatch(getAllDriver()).then(res=> {
      setLoading(false)
    })
  }, [dispatch])


  useEffect(() => {
    if (editingdriver) {
      form.setFieldsValue(editingdriver);
    }
  }, [editingdriver, form]);

  useEffect(() => {
    if(openPasswordModal) {
      setEditinrDriver((prevValues) => ({
        ...prevValues,
        password : ''
      }));
    }
  }, [openPasswordModal])

  useEffect(() => {
    if(searchText.length>2) {
      const propertiesToSearch = ["name", "email", "gender","licenseNumber", "phoneNumber", "userStatus"];
      const lowercaseSearch = searchText.toLowerCase();
      
      const filteredArray = alldrivers.filter(item => {
        for (const prop of propertiesToSearch) {
          const lowercaseValue = item[prop] ? item[prop].toString().toLowerCase() : '';
    
          if (lowercaseValue.includes(lowercaseSearch)) {
            return true; 
          }
        }
        return false;
      });
      setFilteredData(filteredArray)
    }else {
      setFilteredData(alldrivers)
    }
  }, [searchText, alldrivers]);

  const handleCreateDriver = () => {
    setEditinrDriver(null);
    setIsEditMode(false);
    setVisible(true);
  }

  const handleEditDriver = (record) => {
    setEditinrDriver(record);
    setIsEditMode(true);
    setVisible(true);
  };

  const handleDeleteDriver = (record) => {
    setLoading(true)
    dispatch(deleteDriver(record.userId)).then(res => {
      dispatch(getAllDriver(res)).then(res => {
        setLoading(false)
      })
  })
}

  const handleCancel = () => {
    setEditinrDriver(null);
    form.resetFields();
    setVisible(false);
  };

  const handleInputChange = (key, value) => {
    setEditinrDriver((prevValues) => ({
      ...prevValues,
      [key]: value
    }));
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true); 
      setVisible(false);
      form.resetFields(); 
  
      if (isEditMode && editingdriver) {
        await dispatch(editDriver(editingdriver));
      } else {
        const capitalizedValues = { ...values, name: values.name.toUpperCase() };
        console.log(capitalizedValues,'capitalizedValues');
        await dispatch(createDriver(capitalizedValues));
      }
  
      await dispatch(getAllDriver())
      setLoading(false);
    } catch (error) {
      message.error(error)
      setLoading(false); 
    }
  };

  const cancel = () => {
    console.log('Deletion canceled.');
  };

  const validatePhoneNumber = (_, value) => {
    const phoneNumberRegex = /^\d{10}$/;
    if (!value || !value.match(phoneNumberRegex)) {
      return Promise.reject('Phone number must be a 10-digit number');
    }
    return Promise.resolve();
  };

  const updatePassword = async() => {
    try {
      setLoading(true); 
      setOpenPasswordModal(false);
      form.resetFields(); 
      let reqData = {
        newPassword: editingdriver.password,
        userId: editingdriver.userId
      }
      dispatch(updateUserPassword(reqData)).then(res => {
        setIsEditMode(false)
        setEditinrDriver(null)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    } catch (error) {
      message.error(error)
      setLoading(false); 
    }
  }

  const columns = [
    {
      title: 'Index',
      key: 'index',
      render: (text, record, index) => (
        <span className="gx-link">{index + 1}</span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',

    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'License No.',
      dataIndex: 'licenseNumber',
      key: 'licenseNumber',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Mobile',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
      render: text => (
        <span style={{ color: text === 'checkedin' ? 'green' : text === 'checkedout' ? 'red' : '' }}>
          {text === 'checkedin' ? 'Checked-In' : text === 'checkedout' ? 'Checked-Out' : text}
        </span>
      )
    },    
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <EditOutlined style={{ fontSize: '16px', color: '#08c' }} onClick={() => handleEditDriver(record)}/>
          <Popconfirm title="Are you sure to delete this user?" onConfirm={() => handleDeleteDriver(record)} onCancel={cancel} okText="Yes" cancelText="No">
            <DeleteOutlined style={{ fontSize: '16px', color: '#D00', paddingLeft: '10px' }} />
          </Popconfirm>
          <Popconfirm title="Are you sure to reset this user password?" onConfirm={() => {
                setEditinrDriver(record);
                setIsEditMode(true)
                setOpenPasswordModal(true);
                setVisible(false);
              }} onCancel={cancel} okText="Yes" cancelText="No">
            <LockOutlined style={{ fontSize: '16px', color: '#eb2f96', paddingLeft: '10px' }} />
          </Popconfirm>
        </span>
      ),
    }
  ];


  return (
    <Card
      title="Drivers"
      extra={
        <span style={{display: 'flex', flexFlow: 'row', marginTop: '10px'}}>
          <Button type="primary" onClick={handleCreateDriver}>Create Driver</Button>
          <Search placeholder="Search" enterButton onChange={(e) => setSearchText(e.target.value)}/>
        </span>
      }>
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={filteredData}
        bordered={true}
        loading={loading} />
      <Modal
        title={isEditMode ? "Edit Driver" : "Create Driver"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        className="modalStyle"
        >

        <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>

          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter the name' }]}>
            <Input placeholder="Enter your name" onChange={(e) => handleInputChange("name", e.target.value)}/>
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            >
            <Input placeholder="Enter your email" onChange={(e) => handleInputChange("email", e.target.value)} />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true },
              { validator: validatePhoneNumber },
            ]}>
            <Input type="number" placeholder="Enter your phoneNumber" style={{ width: '100%' }} onChange={(e) => handleInputChange("phoneNumber", e.target.value)} />
          </Form.Item>

          <Form.Item
            label="License No:"
            name="licenseNumber"
            rules={[{ required: true, message: 'Please enter the license number' }]}>
            <Input placeholder="Enter your License No"  onChange={(e) => handleInputChange("licenseNumber", e.target.value)}/>
          </Form.Item>

          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: 'Please select gender!' }]}>
            <Select placeholder="select your gender" onChange={(value) => handleInputChange("gender", value)}>
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Status"
            name="userStatus"
            rules={[{ required: true, message: 'Please enter the status' }]}>
            <Select placeholder="Select your status" onChange={(value) => handleInputChange("userStatus", value)}>
              <Option value="checkedin">Checked-In</Option>
              <Option value="checkedout">Checked-Out</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="userType"
            label="Type"
            rules={[{ required: true, message: 'Please select type!' }]}
            initialValue="driver">
            <Select placeholder="Select your type" onChange={(value) => handleInputChange("type", value)} disabled>
              <Option value="driver">Driver</Option>
            </Select>
          </Form.Item>

          <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
            <Button type="danger" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Create"}
            </Button>
          </Form.Item>

        </Form>

      </Modal>

      <Modal
        title={`Change ${editingdriver && editingdriver.name ? `${editingdriver.name}'s` : '' } password?`}
        visible={openPasswordModal}
        onCancel={() => setOpenPasswordModal(false)}
        footer={null}
        className="modalStyle"
        >

        <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter the password' }]}>
            <Input placeholder="Enter your password" onChange={(e) => handleInputChange("password", e.target.value)} />
          </Form.Item>

          <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
            <Button type="primary" onClick={updatePassword}>
              Change
            </Button>
            
          </Form.Item>

        </Form>

      </Modal>

    </Card>
  );
}

export default Driver;
