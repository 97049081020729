import React, { useEffect, useState } from "react";
import { Card, Table, Button, Modal, Form, Input, Popconfirm, message, Select } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { createCarType, deleteCarType, getAllCarType, updateCarType } from "./store/action_creator";
const { Option } = Select;
const { Search } = Input;

const CarType = () => {
    const allCarType = useSelector((state) => state?.car?.carsType)
    const [visible, setVisible] = useState(false);
    const [editingcarType, setEditingCarType] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        setLoading(true)
        dispatch(getAllCarType()).then(res => {
            setLoading(false)
        })
    }, [dispatch])

    useEffect(() => {
        if (editingcarType) {
            form.setFieldsValue(editingcarType);
        }
    }, [editingcarType, form]);

    useEffect(() => {
        if(searchText.length>2) {
          const propertiesToSearch = ["type", "carTypeStatus"];
          const lowercaseSearch = searchText.toLowerCase();
          
          const filteredArray = allCarType.filter(item => {
            for (const prop of propertiesToSearch) {
                const lowercaseValue = item[prop] ? item[prop].toString().toLowerCase() : '';
        
              if (lowercaseValue.includes(lowercaseSearch)) {
                return true; 
              }
            }
            return false;
          });
          setFilteredData(filteredArray)
        }else {
          setFilteredData(allCarType)
        }
      }, [searchText, allCarType]);

    const handleCreateCarType = () => {
        setEditingCarType(null)
        setIsEditMode(false);
        setVisible(true);
    };

    const handleEditCarType = (record) => {
        setEditingCarType(record)
        setIsEditMode(true);
        setVisible(true);
    }

    const handleDeleteCarType = (record) => {
        setLoading(true)
        dispatch(deleteCarType(record.carTypeId)).then(res => {
            dispatch(getAllCarType(res)).then(res => {
                setLoading(false)
            })
        })
    }

    const handleCancel = () => {
        setEditingCarType(null)
        form.resetFields();
        setVisible(false);
    };

    const handleInputChange = (key, value) => {
        setEditingCarType((prevValues) => ({
            ...prevValues,
            [key]: value
        }));
    };

    const handleFormSubmit = async (values) => {
        try {
            setLoading(true);
            setVisible(false);
            form.resetFields();

            if (isEditMode && editingcarType) {
                await dispatch(updateCarType(editingcarType));
            } else {
                await dispatch(createCarType(values));
            }

            await dispatch(getAllCarType());
            setLoading(false);
        } catch (error) {
            message.error(error)
            setLoading(false);
        }
    };

    const cancel = () => {
        console.log('Deletion canceled.');
    };
  
    const columns = [

        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Status',
            dataIndex: 'carTypeStatus',
            key: 'carTypeStatus',
            render: text => (
                <span style={{ color: text === 'Active' ? 'green' : 'red' }}>{text}</span>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <EditOutlined style={{ fontSize: '16px', color: '#08c' }} onClick={() => handleEditCarType(record)} />
                    <Popconfirm title="Are you sure to delete this car type?" onConfirm={() => handleDeleteCarType(record)} onCancel={cancel} okText="Yes" cancelText="No">
                        <DeleteOutlined style={{ fontSize: '16px', color: '#D00', paddingLeft: '10px' }} />
                    </Popconfirm>
                </span>
            ),
        }
    ];

    return (
        <Card
            title="Car Type"
            extra={
                <span style={{display: 'flex', flexFlow: 'row', marginTop: '10px'}}>
                    <Button type="primary" onClick={() => handleCreateCarType()}>Create</Button>
                    <Search placeholder="Search" enterButton onChange={(e) => setSearchText(e.target.value)}/>
                </span>
            }>
            <Table className="gx-table-responsive" columns={columns} dataSource={filteredData} loading={loading} bordered={true}/>
            <Modal
                title={isEditMode ? "Edit Car Type" : "Create Car Type"}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                className="modalStyle"
                >

                <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>

                    <Form.Item
                        label="Car Type"
                        name="type"
                        rules={[{ required: true, message: 'Please enter the company' }]}>
                        <Input placeholder="Enter the car type" onChange={(e) => handleInputChange("type", e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="Status"
                        name="carTypeStatus"
                        rules={[{ required: true, message: 'Please select the status' }]}>
                        <Select placeholder="Select your car status" onChange={(value) => handleInputChange("carTypeStatus", value)}>
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">In-active</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
                        <Button type="danger" onClick={() => handleCancel()}>
                            cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {isEditMode ? "Update" : "Create"}
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>
        </Card>
    )
}

export default CarType;