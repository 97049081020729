import { useEffect, useState } from 'react';
import {httpClient} from "../../../util/Api";
import {  message } from "antd";


export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  useEffect(()=> {
    if(!authUser && localStorage.getItem('AADESH_USER_DATA')){
      let userData = JSON.parse(localStorage.getItem('AADESH_USER_DATA'))
      setAuthUser(userData)
    }
  },[authUser])

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (user, callbackFun) => {
    delete httpClient.defaults.headers.common['Authorization']
    fetchStart();
    httpClient
      .post('/api/v1/staff/login', user)
      .then(({ data }) => {
        if (data) {
          fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
          localStorage.setItem('AADESH_USER_ID', data.userId)
          localStorage.setItem('AADESH_USER_DATA', JSON.stringify(data))
          localStorage.setItem('AADESH_ACCESS_TOKEN', data.token)
          setAuthUser(data);
          message.success(data.message)
          // getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        if(error.response && error.response.data && error.response.data.message) {
          fetchError(error.response.data.message);
        }else {
          fetchError(error)
        }
      });
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post('auth/register', user)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          localStorage.setItem('token', data.token.access_token);
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] = '';
          localStorage.removeItem('AADESH_ACCESS_TOKEN');
          localStorage.removeItem('AADESH_USER_DATA');
          localStorage.removeItem('AADESH_USER_ID');
          setAuthUser(false);
          if (callbackFun) callbackFun();
    // httpClient.post('auth/logout').then(({ data }) => {
    //     if (data.result) {
    //       fetchSuccess();
    //       httpClient.defaults.headers.common['Authorization'] = '';
    //       localStorage.removeItem('token');
    //       setAuthUser(false);
    //       if (callbackFun) callbackFun();
    //     } else {
    //       fetchError(data.error);
    //     }
    //   })
    //   .catch(function (error) {
    //     fetchError(error.message);
    //   });
  };

  const getAuthUser = () => {
    fetchStart();
    
    httpClient
      .post('/staff/login')
      .then(({ data }) => {
        if (data) {
          fetchSuccess();
          setAuthUser(data);
        } else {
          fetchError(data);
        }
      })
      .catch(function (error) {
        httpClient.defaults.headers.common['Authorization'] = '';
        fetchError(error.message);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    // const token = localStorage.getItem('AADESH_ACCESS_TOKEN');
    // if (token) {
    //   httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    // }
    setLoadingUser(false);
    // httpClient
    //   .post('/staff/login')
    //   .then(({ data }) => {
    //     if (data) {
    //       setAuthUser(data);
    //     }
    //     setLoadingUser(false);
    //   })
    //   .catch(function () {
    //     localStorage.removeItem('AADESH_ACCESS_TOKEN');
    //     localStorage.removeItem('AADESH_USER_ID');
    //     httpClient.defaults.headers.common['Authorization'] = '';
    //     setLoadingUser(false);
    //   });
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
