import { CommonService } from '../../../../_services/CommonService'
import * as actionTypes from './action-type'
import { message } from "antd";


export const setAllRide = (data) => {
    return {
        type: actionTypes.SET_RIDE,
        data
    }
}

export const getAllRides = () => {
    return (dispatch) => {
        return CommonService.httpGetService('/cars/ride').then((res) => {
            if (res && res['data']) {
                dispatch(setAllRide(res['data']))
            } else {
                dispatch(setAllRide(null))
            }
        }).catch(err => {
            message.error(err)
        })
    }
}

export const createRide = (data) => {
    return (dispatch) => {
        return CommonService.httpPostService('/rides/request/schedule', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err)
        })
    }
}


export const updateRide = (data) => {
    return(dispatch) => {
        return CommonService.httpPutService('/cars/ride', data).then((res) => {
            message.success(res.message)
        }).catch(err => {
            message.error(err)
        })
    }   
}

export const deleteRide = (id) => {
    return (dispatch) => {
        return CommonService.httpDeleteService(`/cars/ride/${id}`).then((res)=> {
            message.success(res.message)
        }).catch(err => {
            message.error(err)
        })
    }
}

// export const locationApi = (input) => {
//     const API_KEY = 'AIzaSyCaMj8Gm5QB6aXTLeC4NJzItjIU4kmvXUc';
//     // const apiUrl = `/maps/api/place/autocomplete/json?input=${input}&key=${API_KEY}&components=country:IN&administrative_area:GJ&locality:Rajkot`

//     return (dispatch) => {
//         if (input.trim() !== '') {
//             return CommonService.httpGetService(`/maps/api/place/autocomplete/json?input=${input}&key=${API_KEY}&components=country%3Ain`).then((res) => {
//                 return res
//             }).catch(err => {
//                 return err
//             })
//         }
//     }
// }  

export const locationApi = (input) => {
    const API_KEY = 'AIzaSyCaMj8Gm5QB6aXTLeC4NJzItjIU4kmvXUc';

    return (dispatch) => {
        if (input.trim() !== '') {
            return CommonService.httpGetService(`/maps/api/place/autocomplete/json?input=${input}&key=${API_KEY}&components=country%3Ain`).then((res) => {
                console.log(res,'res');
                const filteredResults = res.predictions.filter((prediction) => {
                    const components = prediction.description || '';
                    return components.includes('Gujarat') 
                });

                return filteredResults;
            }).catch(err => {
                return err;
            });
        }
    }
}

export const getGeoCode = (PickupPlaceId) => {
  const API_KEY = 'AIzaSyCaMj8Gm5QB6aXTLeC4NJzItjIU4kmvXUc';
  return(dispatch) => {
    return CommonService.httpGetService(`/maps/api/geocode/json?place_id=${PickupPlaceId}&key=${API_KEY}`).then((res) => {
        return res
    }).catch(err => {   
        return err
    })
  }
}


export const distanceUrl = (pickupLocationLatitude, pickupLocationLongitude, dropoffLocationLatitude, dropoffLocationLongitude) => {
    const API_KEY = 'AIzaSyCaMj8Gm5QB6aXTLeC4NJzItjIU4kmvXUc';
  return(dispatch) => {
    return CommonService.httpGetService(`/maps/api/directions/json?origin=${pickupLocationLatitude},${pickupLocationLongitude}&destination=${dropoffLocationLatitude},${dropoffLocationLongitude}&mode=driving&key=${API_KEY}`).then((res) => {
        return res
    }).catch(err => {   
        return err
    })
  }

}


export const otpRide = (record) => {
    return(dispatch) => {
        return CommonService.httpGetService(`/cars/ride/otp/${record.rideId}`).then((res) => {
            console.log(res,'res');
        }).catch(err => {
            return err
        })
    }
}