import * as actionTypes from './action_type'


const intialState = {
    Airportcars: [],
}

export const AirportcarReducer= (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.SET_AIRPORT_CARS:
            return {
                ...state,
                Airportcars: action.data
            };    
        default:
            return state;
    }
}