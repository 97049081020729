import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createAirportCar, deleteAirportCar, getAllAirportCars, updateAirportCar } from "./store/action_creator"
import { Button, Card, Modal, Popconfirm, Table, Form, Input, message , Select} from "antd"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { getAllCarType } from "../Car/store/action_creator"
const { Search } = Input
const { Option } = Select



const Airport = () => {
    const allAirportCars = useSelector((state) => state?.airport?.Airportcars)
    const carsType = useSelector((state) => state?.car?.carsType)
    console.log(carsType,'carsType');

    console.log(allAirportCars,'allAirportCars');
    const dispatch = useDispatch()
    const [airportRate, setAirportRate] = useState([])
    const [loading,setLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [editingAirportcar, setEditingAirportCar] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [filteredData, setFilteredData] = useState([])


    useEffect(() => {
        setLoading(true)
        dispatch(getAllAirportCars())
          .then(() => dispatch(getAllCarType()))
          .finally(() => setLoading(false));
      }, [dispatch]);

    useEffect(() => {
        if(allAirportCars){
            setAirportRate(allAirportCars)
        }
    },[allAirportCars])

    useEffect(() => {
        if (editingAirportcar) {
            form.setFieldsValue(editingAirportcar);
        }
    }, [editingAirportcar, form]);

    const handleCreateCar = () => {
        setEditingAirportCar(null);
        setIsEditMode(false);
        setVisible(true);
    }

    const handleEditAirportCar = (record) => {
        setEditingAirportCar(record);
        setIsEditMode(true);
        setVisible(true);
    }

    const handleDeleteAirportRate = (record) => {
        setLoading(true)
        dispatch(deleteAirportCar(record.rateId)).then(res => {
            dispatch(getAllAirportCars(res)).then(res => {
            setLoading(false)
          })
        })
      }

    const cancel = () => {
        console.log('Deletion canceled.');
    };

    const handleCancel = () => {
        setEditingAirportCar(null);
        form.resetFields();
        setVisible(false);
    }

    useEffect(() => {
        if(searchText.length>2) {
          const propertiesToSearch = ["carType", "distanceBelow_40", "distanceBelow_45","distanceBelow_50", "distanceBelow_60"];
          const lowercaseSearch = searchText.toLowerCase();
          
          const filteredArray = airportRate.filter(item => {
            for (const prop of propertiesToSearch) {
                const lowercaseValue = item[prop] ? item[prop].toString().toLowerCase() : '';
        
              if (lowercaseValue.includes(lowercaseSearch)) {
                return true; 
              }
            }
            return false;
          });
          setFilteredData(filteredArray)
        }else {
          setFilteredData(airportRate)
        }
      }, [searchText, airportRate]);


    const handleInputChange = (key, value) => {
        setEditingAirportCar((prevValues) => ({
            ...prevValues,
            [key]: value
        }));
    };
    
    const handleFormSubmit = async (values) => {
        console.log(values,'values');
        try {
          setLoading(true); 
          setVisible(false);
          form.resetFields(); 

          if (isEditMode && editingAirportcar) {
            let formValues = JSON.parse(JSON.stringify(editingAirportcar))
            let selectedCar = carsType.find(item => item.carTypeId === formValues.carTypeId)
            formValues['carType'] = selectedCar.type
            await dispatch(updateAirportCar(editingAirportcar))
          } else {
            let formValues = JSON.parse(JSON.stringify(values))
            let selectedCar = carsType.find(item => item.carTypeId === formValues.carTypeId)
            console.log(selectedCar,'selelel');
            formValues['carType'] = selectedCar.type
            await dispatch(createAirportCar(formValues))
          }

          await dispatch(getAllAirportCars())
          setLoading(false);
        } catch (error) {
            message.error(error)
            setLoading(false);
        }
    }

    const columns = [
       
        {
            title: 'Car Type',
            dataIndex: 'carType',
            key: 'carType',
        },
        {
            title: 'Distance < 40',
            dataIndex: 'distanceBelow_40',
            key: 'distanceBelow_40',
        },
        {
            title: 'Distance < 45',
            dataIndex: 'distanceBelow_45',
            key: 'distanceBelow_45',
        },
        {
            title: 'Distance < 50',
            dataIndex: 'distanceBelow_50',
            key: 'distanceBelow_50',
        },
        {
            title: 'Distance < 60',
            dataIndex: 'distanceBelow_60',
            key: 'distanceBelow_60',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <EditOutlined style={{ fontSize: '16px', color: '#08c' }} onClick={() => handleEditAirportCar(record)} />
                    <Popconfirm title="Are you sure to delete this car?" onConfirm={() => handleDeleteAirportRate(record)} onCancel={cancel} okText="Yes" cancelText="No">
                        <DeleteOutlined style={{ fontSize: '16px', color: '#D00', paddingLeft: '10px' }} />
                    </Popconfirm>
                </span>
            ),
        }
    ];

    return (
        <Card
        title="Airport Rate"
        extra={
        <span style={{display: 'flex', flexFlow: 'row', marginTop: '10px'}}>
            <Button type="primary" onClick={() => handleCreateCar()}>Create Airport Rate</Button>
            <Search placeholder="Search" enterButton onChange={(e) => setSearchText(e.target.value)}/>
        </span>
        }
        >
        <Table className="gx-table-responsive" columns={columns} dataSource={filteredData} loading={loading} bordered={true}/>
        <Modal
            title={isEditMode ? "Edit Airport Rate" : "Create Airport Rate"}
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            className="modalStyle"
            >

            <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 20 }}>

                <Form.Item
                    label="Distance < 40"
                    name="distanceBelow_40"
                    rules={[{ required: true, message: 'Please enter the distance below 40' }]}>
                    <Input placeholder="Enter the distance below 40" onChange={(e) => handleInputChange("distanceBelow_40", e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="Distance < 45"
                    name="distanceBelow_45"
                    rules={[{ required: true, message: 'Please enter the distance below 45' }]}>
                    <Input placeholder="Enter the distance below 45" onChange={(e) => handleInputChange("distanceBelow_45", e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="Distance < 50"
                    name="distanceBelow_50"
                    rules={[{ required: true, message: 'Please enter the distance below 50' }]}>
                    <Input placeholder="Enter the distance below 50" onChange={(e) => handleInputChange("distanceBelow_50", e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="Distance < 60"
                    name="distanceBelow_60"
                    rules={[{ required: true, message: 'Please enter the distance below 60' }]}>
                    <Input placeholder="Enter the distance below 60" onChange={(e) => handleInputChange("distanceBelow_60", e.target.value)} />
                </Form.Item>

                    <Form.Item
                        name="carTypeId"
                        label="Type"
                        rules={[{ required: true, message: 'Please select Type!' }]}>
                        <Select placeholder="select your type" onChange={(value) => handleInputChange("carTypeId", value)}>
                            {carsType.map((type, index) => (
                                <Option key={index} value={type.carTypeId}>
                                    {type.type}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item style={{ marginTop: '40px', marginLeft: '150px' }}>
                        <Button type="danger" onClick={handleCancel}>
                            cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {isEditMode ? "Update" : "Create"}
                        </Button>
                    </Form.Item>

            </Form>
          </Modal>
        </Card>
    )
}

export default Airport;
