import * as actionTypes from './action-type'

const intialState = {
    rides: [],
}

export const rideReducer= (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.SET_RIDE:
            return {
                ...state,
                rides: action.data
            };
        default:
            return state;
    }
}