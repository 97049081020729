export const CommonService = {
    httpGetService,
    httpPostService,
    httpPutService,
    httpDeleteService
};


function httpGetService(url) {
    const token = localStorage.getItem('AADESH_ACCESS_TOKEN')

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };

    return fetch(`/api/v1${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}


function httpPostService(url, data) {
    const token = localStorage.getItem('AADESH_ACCESS_TOKEN')

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(data)
    };

    return fetch(`/api/v1${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function httpPutService(url, data) {
    const token = localStorage.getItem('AADESH_ACCESS_TOKEN')

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,

        },
        body: JSON.stringify(data)
    };

    return fetch(`/api/v1${url}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function httpDeleteService(url) {
    const token = localStorage.getItem('AADESH_ACCESS_TOKEN')

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };

    return fetch(`/api/v1${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = data.message;
            return Promise.reject(error);
        }
        return data;

    });
}